iframe {
  display: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #0f1535 inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}
